.App {
  background: rgb(247, 249, 252);
}

.button-icon-left {
  margin-right: 8px;
}

.button-icon-right {
  margin-left: 8px;
}


.header {
  h3 {
    font-size: 1.5rem;
  }
  nav {
    margin-top: 8px;
  }
  hr {
    margin: 16px 0;
  }
  .header-actions {
    margin-left: auto;
    a {
      margin-left: 16px;
    }
  }
}

.content {
  margin-bottom: 24px;
  .paper-content {
    border-radius: 4px;
  }
}

.MuiPaper-elevation1 {
  box-shadow: 0 0 14px 0 rgba(53,64,82,.05) !important;
}

.form-actions {
  margin-top: 24px;
  button {
    float: right;
    margin-left: 16px;
  }
}

.padding-2 {
  padding: 16px;
  width: 100%;
}